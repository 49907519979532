import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({ pageCount, paginate, reduxPage }) {
	const [currentPage, setCurrentPage] = useState(0)

	useEffect(() => {
		if (reduxPage) {
			setCurrentPage(reduxPage)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{pageCount > 1 &&
				<ReactPaginate
					forcePage={currentPage}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={paginate}
					containerClassName={'pagination'}
					activeClassName={'active'}
					pageClassName={'page-item'}
				/>
			}
		</>
	)
}

export default Pagination
