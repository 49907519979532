import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { id } = useParams();
	const URL = "/services/admin/api/"
	const URL_FILE = "/services/admin/api/"

	const { t } = useTranslation()
	const history = useHistory();

	const [data, setData] = useState({
		imageUrl: ''
	})

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE(URL_FILE, formData);
		setData({ ...data, imageUrl: response.url })
	}

	async function update() {
		const response = await PUT(URL + '/' + id)
		if (response)
			history.push('/documents-in')
	}

	async function getData() {
		const response = await GET(URL + '/' + id)
		setData(response)
	}

	useEffect(() => {
		if (id) {
			getData()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					<div className="d-flex">
						{t('MY_ORGANIZATION')}
						<span className="ms-2 me-2">|</span>
						{t('EDIT')}
					</div>
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={update} autoComplete="off">
						<div className="row">
							<div className="col-md-4 mt-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{!data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file">
													<i className="uil-times"></i>
												</button>
												<img src="https://erp.2shop.uz/images/company202111041923.png"
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={fileUpload} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Название компании<span className="required-mark">*</span></label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<label>ИНН</label>
									<input type="number" className="form-control" />
								</div>
								<div className="form-group">
									<label>ОКЭД</label>
									<input type="text" className="form-control" />
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Директор<span className="required-mark">*</span></label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<label>Телефон<span className="required-mark">*</span></label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<label>Аддресс</label>
									<input type="text" className="form-control" />
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-end mt-5">
							<button className="btn btn-primary btn-rounded btn-wide">Сохранить</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

