import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "../../../common/languages"

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz-Latn-UZ.png'

const LanguageDropdown = () => {
	// Declare a new state variable, which we'll call "menu"
	const [selectedLang, setSelectedLang] = useState("")
	const [menu, setMenu] = useState(false)
	const { t } = useTranslation()

	useEffect(() => {
		const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
		setSelectedLang(currentLanguage)
	}, [])

	const changeLanguageAction = lang => {
		//set language as i18n
		i18n.changeLanguage(lang)
		localStorage.setItem("I18N_LANGUAGE", lang)
		setSelectedLang(lang)
	}

	const toggle = () => {
		setMenu(!menu)
	}

	function getCurrentLocaleData () {
		const locale = i18n.language
		if (locale === 'ru')      return "RUSSIAN"
		else if (locale === 'uz-Latn-UZ') {return "UZBEK_LATN"}
		else if (locale === 'uz-Cyrl-UZ') {return "UZBEK_CYRL"}
	}

	return (
		<>
			<Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
				<DropdownToggle className="btn header-item waves-effect" tag="button">
					<img
						className="me-2"
						src={i18n.language === "ru" ? ru : uzLatn}
						alt="Header Language"
						height="16"
					/>
					{t(getCurrentLocaleData())}
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end" right style={{ 'top': '3px!importnat' }}>
					{map(Object.keys(languages), key => (
						<DropdownItem
							key={key}
							onClick={() => changeLanguageAction(languages[key]['label'])}
							className={`lang-item notify-item ${selectedLang === key ? "active" : "none"
								}`}
						>
							<img
								src={get(languages, `${key}.flag`)}
								alt="react"
								className="me-1"
								height="12"
							/>
							<span className="align-middle">
								{t(languages[key]['i18n'])}
							</span>
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</>
	)
}

export default withTranslation()(LanguageDropdown)
