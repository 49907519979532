import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { GET, PGET } from 'helpers/api'
import { SET_TABLE_FILTER } from "store/actions"
import { formatMoney } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const URL = '/services/admin/api/product-pageList'

	const settings = useSelector(state => state.settings)
	const reduxTableFilter = useSelector(state => state.tableFilter)

	const [showModal, setShowModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)

	const [searchValue, setSearchValue] = useState('')

	const [data, setData] = useState([])
	const [statistics, setStatistics] = useState([])

	async function getData(fromSearch = false) {
		var filter = {}
		if (reduxTableFilter.page === 'products') {
			filter = { ...reduxTableFilter.filters }
			if (!fromSearch) {
				if (reduxTableFilter?.filters?.search) {
					setSearchValue(reduxTableFilter?.filters?.search)
				}
			} else {
				setSearchValue('')
				filter.search = ''
			}
		}
		const response = await PGET('/services/admin/api/product-pageList', filter, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/product-pageList', { page: data.selected, size: settings.size, search: searchValue }, { loader: true })
		setData(response.data);
		dispatch(SET_TABLE_FILTER({
			'page': 'products',
			'filters': {
				'page': data.selected,
				'search': searchValue
			}
		}))
	}

	async function openModal() {
		const response = await GET('/services/admin/api/product-changed-list')
		setStatistics(response)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function search(data, searchValue, total) {
		setData(data);
		dispatch(SET_TABLE_FILTER({
			'page': 'products',
			'filters': {
				'page': 0,
				'search': searchValue
			}
		}))
		setSearchValue(searchValue)
		setPageCount(Math.ceil(total / settings.size))
		if (searchValue.length > 0) {
			setPageCount(Math.ceil(total / settings.size))
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PRODUCTS')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/products-create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData} searchValue={searchValue}></Search>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => openModal()}>
								{t('STATISTICS')}
							</button>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('IMAGE2')}</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('CATEGORY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>
											{item.imageUrl ?
												<img src={'https://cabinet.mdokon.uz' + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.categoryName}</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link to={'/products-update/' + item.id}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={reduxTableFilter?.filters?.page}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('STATISTICS')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th>{t('LOGIN')}</th>
								<th>{t('CREATED')}</th>
								<th>{t('UPDATED')}</th>
							</tr>
						</thead>
						<tbody>
							{statistics.map((item, index) => (
								<tr key={index}>
									<td>{item.login}</td>
									<td>{formatMoney(item.createdCount, 0)}</td>
									<td>{formatMoney(item.updatedCount, 0)}</td>
								</tr>
							))
							}
						</tbody>
					</table>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
