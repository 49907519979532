import React from 'react'
import { Link } from 'react-router-dom'

function Index() {
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					Главная панель
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/documents-in/create">
						<i className="uil uil-plus me-2"></i>
						Создать
					</Link>
				</div>
			</div>
    </>
	)
}

export default Index
