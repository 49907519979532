const initialState = {
	'size': 20,
	'decimalPoint': 0,
	'navTabKey': 'link_1',
	'reportTab': 1,
	'anything': '',
}

const pagination = function (state = initialState, action) {
  switch (action.type) {
    case "SET_PAGINATION_SIZE":
      return {...state, 'size': action.payload};
		case "SET_NAV_TAB":
			return {...state, 'navTabKey': action.payload};
		case "SET_DECIMAL_POINT":
			return {...state, 'decimalPoint': action.payload};
		case "SET_REPORT_TAB":
			return {...state, 'reportTab': action.payload};
		case "SET_ANYTHING":
			return {...state, 'anything': action.payload};
    default:
      return state;
  }
};

export default pagination