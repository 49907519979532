const initialState = {
	'page': '',
	'filters': {},
}

const tableFilter = function (state = initialState, action) {
	switch (action.type) {
		case "SET_TABLE_FILTER":
			return { ...state, 'page': action.payload.page, 'filters': action.payload.filters };
		default:
			return state;
	}
};

export default tableFilter