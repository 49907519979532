import React, { useEffect, useState } from "react"
import { useHistory, useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const history = useHistory();

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			imageUrl: '',
			name: '',
			barcode: '',
			brandName: '',
			manufacturerName: '',
			unitList: [],
			uomId: 1,
			categoryId: '2',
			full: '0'
		}
	});
	const { errors } = formState

	const [imageUrl, setImageUrl] = useState('');
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [categories, setCategories] = useState([])
	const [uoms, setUoms] = useState([])
	const [data, setData] = useState({
		unitList: []
	})
	const [unit, setUnit] = useState({ name: '', quantity: '' })
	const [showModifications, setShowModifications] = useState(false)

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/admin/api/upload/image', formData, {}, { loader: true });
		setImageUrl(response.url)
		setValue('imageUrl', response.url)
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.unitList = data.unitList
		var response;
		if (sendData.id) {
			response = await PUT('/services/admin/api/product', sendData, { loader: true })
		} else {
			response = await POST('/services/admin/api/product', sendData, { loader: true })
		}

		if (response) {
			history.push('/products')
		}
	}

	function addUnit() {
		var dataCopy = { ...data }
		if (dataCopy.unitList.length === 0) {
			dataCopy.unitList.push({ ...unit })
			setUnit({ name: "", quantity: "" })
		} else {
			var unitFound = dataCopy.unitList.find(x => x.name === unit.name)
			if (!unitFound) {
				dataCopy.unitList.push({ ...unit })
				setUnit({ name: "", quantity: "" })
			}
		}
	}

	function deleteUnit(index) {
		var dataCopy = { ...data }
		dataCopy.unitList.splice(index, 1)
		setData(dataCopy)
	}

	async function searchFromDatabase() {
		const response = await GET('/services/admin/api/product-db-barcode?barcode=' + getValues('barcode'), {})
		if (response) {
			setValue('name', response.name)
			setValue('brandName', response.brandName)
			setValue('manufacturerName', response.manufacturerName)
			setValue('categoryId', response.categoryId)
			setValue('uomId', response.uomId)
			setValue('imageUrl', response.imageUrl)
			setImageUrl(response.imageUrl)
		}
	}

	function selectCategory(selectedOption) {
		var copyCategories = [...categories]
		var index = copyCategories.findIndex(item => item.id === selectedOption.id)
		for (let i = 0; i < selectedOption?.options?.length; i++) {
			selectedOption.options[i].show = !selectedOption.options[i].show;
		}
		copyCategories[index] = selectedOption
		setCategories(copyCategories)
	}

	async function getData() {
		const response = await GET('/services/admin/api/product/' + id, { loader: true })
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setImageUrl(response.imageUrl)
		if (response.unitList?.length > 0) {
			setShowModifications(true)
			setData({ ...data, 'unitList': response.unitList })
		}
	}

	async function getCategories() {
		const response = await GET('/services/admin/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				// response[i]['label'] = '> ' + response[i]['name']
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getUoms() {
		const response = await GET('/services/admin/api/product-uom-helper')
		setUoms(response)
	}

	useEffect(() => {
		if (id) {
			getData()
			getCategories()
			getUoms()
		} else {
			getCategories()
			getUoms()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			props.data.show ?
				<div style={{
					padding: '0 0 0 20px',
					color: '#626262',
					fontWeight: 'normal',
					fontSize: '.8rem'
				}}>
					<components.Option {...props} />
				</div>
				:
				<></>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: '#495057',
				fontWeight: 'bold',
				backgroundColor: '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<div className="d-flex align-items-center">
					<components.Option {...props} />
					<i className="uil uil-angle-down fz-30" />
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('PRODUCT')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						{!id ?
							<h6>{t('CREATE')}</h6>
							:
							<h6>{t('EDIT')}</h6>
						}
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row">
							<div className="col-md-3 mt-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file" onClick={() => {
													setImageUrl('')
													setValue('imageUrl', '')
												}}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://cabinet.mdokon.uz' + imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name" {...register('name')} autoFocus />
									<span className="text-danger text-sm">{errors.name?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('BARCODE')}<span className="required-mark">*</span></label>
									<div className="position-relative">
										<input type="number" className="form-control" tabIndex="2" name="barcode" disabled={id} {...register('barcode')} />
										<span className="text-danger text-sm">{errors.barcode?.message}</span>
										<i className="uil-qrcode-scan input-right-icon" onClick={searchFromDatabase}></i>
									</div>
								</div>
								<div className="form-group">
									<label>Международная марка</label>
									<input type="text" className="form-control" name="gtin" {...register('gtin')} />
									<span className="text-danger text-sm">{errors.gtin?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('CATEGORY')}<span className="required-mark">*</span></label>
									<Controller
										name="categoryId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												components={{ GroupHeading: GroupHead, Option }}
												options={categories}
												value={categoriesInitial.find(option => option.id === getValues('categoryId'))}
												onChange={(e) => setValue('categoryId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												classNamePrefix="react-select"
											/>}
									/>
								</div>
								<div className="form-group">
									<label>{t('UNIT_OF_MEASUREMENT')}<span className="required-mark">*</span></label>
									<Controller
										name="uomId"
										control={control}
										render={({ field }) =>
											<Select
												//isDisabled={id}
												tabIndex={3}
												{...field}
												options={uoms}
												value={uoms.find(option => option.id === getValues('uomId'))}
												onChange={(e) => setValue('uomId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
								</div>
								<div className="form-group">
									<label>Заполняемость</label>
									<input type="number" className="form-control" name="full" {...register('full')} />
									<span className="text-danger text-sm">{errors.full?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('MANUFACTURER')}</label>
									<input type="text" className="form-control" name="manufacturerName" {...register('manufacturerName')} />
									<span className="text-danger text-sm">{errors.manufacturerName?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('TRADEMARK')}</label>
									<input type="text" className="form-control" name="brandName" {...register('brandName')} />
									<span className="text-danger text-sm">{errors.brandName?.message}</span>
								</div>
							</div>
						</div>

						<div className="col-md-12 input-borer-only p-3 my-3">
							<div className="d-flex">
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input"
										checked={showModifications}
										onChange={() => setShowModifications(!showModifications)} />
								</div>
								<h4><strong>{t('UNIT_OF_MEASUREMENT')}</strong></h4>
							</div>

							<hr />
							{showModifications &&
								<div className="col-md-6">
									<div className="table-responsive">
										<table className="table table-striped mb-0">
											<thead>
												<tr>
													<th>{t('MEASUREMENT_DESCRIPTION')}</th>
													<th>{t('NUMBER_OF_UNITS')}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<input type="text" className="form-control" value={unit.name}
															onChange={(e) => setUnit({ ...unit, 'name': e.target.value })} />
													</td>
													<td>
														<input type="number" className="form-control" value={unit.quantity}
															onChange={(e) => setUnit({ ...unit, 'quantity': e.target.value })} />
													</td>
													<td>
														<button type="button" className="btn btn-primary w-100"
															disabled={!unit.name || !unit.quantity}
															onClick={() => addUnit()}>
															{t('ADD')}
														</button>
													</td>
												</tr>
												{data.unitList?.map((item, index) => (
													<tr key={index}>
														<td>{item.name}</td>
														<td>{item.quantity}</td>
														<td className="d-flex justify-content-center" onClick={() => deleteUnit(index)}>
															<div className="table-action-button table-action-danger-button">
																<i className="uil uil-trash-alt"></i>
															</div>
														</td>
													</tr>
												))
												}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/products">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide btn-wide" tabIndex="4" onClick={handleSubmit(createUpdate)}>{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

