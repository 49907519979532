import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/Parts/Search'
import Pagination from 'pages/Parts/Pagination'

import { PGET } from 'helpers/api'
import { formatMoney } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const URL = '/services/admin/api/info-product-price'

	const settings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		var filter = {}
		const response = await PGET('/services/admin/api/info-product-price', filter, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/info-product-price', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	} 

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		// getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PRODUCTS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('BARCODE')}</th>
									<th className="text-center">{t('UNIT_OF_MEASUREMENT')}</th>

									<th className="text-center">{t('MIN_PRICE')}</th>
									<th className="text-center">{t('MAX_PRICE')}</th>
									<th className="text-center">{t('AVG_PRICE')}</th>

									<th className="text-center">{t('MIN_SALE_PRICE')}</th>
									<th className="text-center">{t('MAX_SALE_PRICE')}</th>
									<th className="text-center">{t('AVG_SALE_PRICE')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.rowNum}. {item.productBarcode}</td>
										<td className="text-center">{item.uomName}</td>

										<td className="text-center">{formatMoney(item.minPrice)}</td>
										<td className="text-center">{formatMoney(item.maxPrice)}</td>
										<td className="text-center">{formatMoney(item.avgPrice)}</td>
										<td className="text-center">{formatMoney(item.minSalePrice)}</td>
										<td className="text-center">{formatMoney(item.maxSalePrice)}</td>
										<td className="text-center">{formatMoney(item.avgSalePrice)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
